document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll('.adam__rs').forEach(el => {
        
        function updateFilters(page) {
            const urlParams = new URLSearchParams(window.location.search);
            if (!page) { page = urlParams.get('page') ? parseInt(urlParams.get('page')) : 1; }
            urlParams.set('page', page);

            let filters = { language: [], type: [], technology: [], product: [] };
            Object.keys(filters).forEach(filter => {
                el.querySelectorAll('.filter--' + filter + ' input[type="checkbox"]:checked').forEach(check => {
                    filters[filter] = [ ...filters[filter], check.value];
                });

                if (filters[filter].length > 0) { urlParams.set(filter, filters[filter].join(',')); }
                else { urlParams.delete(filter); }
            });
            window.location.search = urlParams.toString();
        }

        el.querySelectorAll('.adam__rs__filter input[type="checkbox"]').forEach(check => {
            check.addEventListener('change', () => { updateFilters(1); });
        });
        el.querySelectorAll('.adam__resource__pagination__link').forEach(link => {
            link.addEventListener('click', () => { updateFilters(link.getAttribute('data-page')); });
        });
        el.querySelectorAll('.adam__rs__filters').forEach(button => {
            const sidebar = el.querySelector('.adam__rs__sidebar');
            button.addEventListener('click', () => {
                sidebar.classList.toggle('sidebar--hide', !sidebar.classList.contains('sidebar--hide'));
            });
        });
    })
});